import './bootstrap';

import intlTelInput from 'intl-tel-input';
import { ru } from "intl-tel-input/i18n";

window.intlTelInput = intlTelInput;

window.createCORSRequest = (method, url) => {
    let xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
        // XHR for Chrome/Firefox/Opera/Safari.
        xhr.open(method, url, true);
    } else if (typeof XDomainRequest != "undefined") {
        // XDomainRequest for IE.
        xhr = new XDomainRequest();
        xhr.open(method, url);
    } else {
        // CORS not supported.
        xhr = null;
    }
    return xhr;
};

window.initIntlTelInput = el => {
    intlTelInput(el, {
        i18n: ru,
        loadUtilsOnInit: "/vendor/utils.js",
        autoHideDialCode: false,
        separateDialCode: true,
        autoPlaceholder: "aggressive",
        initialCountry: "auto",
        geoIpLookup(success, failure) {
            let xhr = window.createCORSRequest('GET', "https://api.country.is/");
            if (!xhr) {
                console.error('CORS not supported');
                return;
            }
            // Response handlers.
            xhr.onload = function () {
                let response = JSON.parse(xhr.responseText);
                let countryCode = (response && response.country) ? response.country : "RU";
                success(countryCode);
            };
            xhr.onerror = function () {
                console.error('Woops, there was an error making the request.');
                success('RU');
            };
            xhr.send();
        },
    });
    // tel input change event function
    const telInputChangeEventFunc = function () {
        const itiPhone = window.intlTelInput.instances[this.dataset.intlTelInputId];
        if (this.dataset.target) {
            const phoneInput = document.getElementById(this.dataset.target);
            if (phoneInput) {
                let oldValue = phoneInput.value?.trim();
                if (oldValue !== '' && oldValue.charAt(0) !== '+' && oldValue.charAt(0) !== '0' && itiPhone.isValidNumber() === null) {
                    oldValue = `+${oldValue}`;
                    phoneInput.value = oldValue;
                }
                if (itiPhone.getNumber()?.trim() !== '') {
                    if (itiPhone.isValidNumber()) {
                        phoneInput.value = itiPhone.getNumber();
                    } else {
                        phoneInput.value = '';
                    }
                } else {
                    if (oldValue !== '' && itiPhone.isValidNumber() === null) {
                        itiPhone.setNumber(oldValue);
                        phoneInput.value = itiPhone.getNumber();
                    }
                }
                if (phoneInput.value !== oldValue && phoneInput.value !== '' && (itiPhone.isValidNumber() === true || itiPhone.isValidNumber() === null)) {
                    phoneInput.dispatchEvent(new KeyboardEvent('change'));
                    phoneInput.dispatchEvent(new KeyboardEvent('input'));
                    phoneInput.dispatchEvent(new CustomEvent('telchange', {
                        detail: {
                            valid: true,
                            validNumber: phoneInput.value,
                            number: itiPhone.getNumber(),
                            country: itiPhone.getSelectedCountryData().iso2?.toUpperCase(),
                            countryName: itiPhone.getSelectedCountryData().name,
                            dialCode: itiPhone.getSelectedCountryData().dialCode
                        }
                    }));
                } else {
                    if (itiPhone.isValidNumber() === false) {
                        phoneInput.dispatchEvent(new KeyboardEvent('change'));
                        phoneInput.dispatchEvent(new KeyboardEvent('input'));
                        phoneInput.dispatchEvent(new CustomEvent('telchange', {
                            detail: {
                                valid: false,
                                validNumber: phoneInput.value,
                                number: itiPhone.getNumber(),
                                country: itiPhone.getSelectedCountryData().iso2?.toUpperCase(),
                                countryName: itiPhone.getSelectedCountryData().name,
                                dialCode: itiPhone.getSelectedCountryData().dialCode
                            }
                        }));
                    }
                }
            }
        }
    }

    el.removeEventListener('change', telInputChangeEventFunc);
    el.addEventListener('change', telInputChangeEventFunc);

    // listen and sync phone number with tel input if any
    if (el.dataset.target) {
        const phoneInput = document.getElementById(el.dataset.target);
        const itiPhone = window.intlTelInput.instances[el.dataset.intlTelInputId];
        if (phoneInput) {
            let oldValue = phoneInput.value?.trim();
            if (oldValue !== '' && oldValue.charAt(0) !== '+' && oldValue.charAt(0) !== '0') {
                oldValue = `+${oldValue}`;
            }
            const changeHandler = function () {
                let newValue = this.value?.trim();
                if (newValue !== oldValue && newValue !== '') {
                    itiPhone.setNumber(newValue);
                }
            }
            phoneInput.removeEventListener('change', changeHandler);
            phoneInput.addEventListener('change', changeHandler);
        }
    }



    // el.addEventListener('change', function (event) {
    //     let instance = intlTelInputGlobals.instances[event.target.dataset.intlTelInputId];
    //     document.getElementById(event.target.dataset.target).value = instance.getNumber();
    // });
};
window.renderTelInput = () => {
    const phoneInputs = document.querySelectorAll("input[type=tel]");
    if (phoneInputs.length > 0) {
        phoneInputs.forEach(input => {
            initIntlTelInput(input);
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    //**************** PHONE INPUT FUNCTIONALITY START ********************************//
    setTimeout(function () {
        renderTelInput();
    }, 5);

    // user dispatched browser events to re-render the tel inputs
    document.addEventListener("telDOMChanged", function () {
        setTimeout(function () {
            renderTelInput();
        }, 5);
    });
    if (window.hasOwnProperty('Livewire')) {
        window.Livewire.hook('component.initialized', component => {
            setTimeout(function () {
                renderTelInput();
            }, 5);
        });
    }

    //**************** PHONE INPUT FUNCTIONALITY END ********************************//
});
